.Header {
    /* bachground color taken from regression website */
    background-color: #00144a; 
    color: white;
    padding: 0 rem;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.LogoDiv{
    margin-top: 10px;
}

.HeaderText{    
    margin-left: 10px;
    font-size: 25px;
    margin-top: 10px;
}