.MainBackground {
    background-color:#eaebef;;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.VerifyCode {
    text-align: center;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    background-color:#eaebef;
}

.Header{
    margin-top: 10px;
    color: #8e8a8af7;
}

.Logo{
    margin-top: 0px;
    align-items: left;
    height: 60px;
    width: 180px;
}


hr {
    background-color: rgb(25, 24, 24);
    border-style: none;
    border-top-style: dotted;
    height: 0px;
    width: 100%;
    margin-bottom: 50px;
  }

.Border {
    text-align: center;
    display: flex; 
    /* justify-content: center;  */
    align-items: center; 
    flex-direction: column;
    background-color: #ffffff;
    margin-top: 60px;
    padding-bottom: 70px;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    border: 2px solid #ccc;
}

.InputClass {
    width: 350px;
    height: 40px;
    margin-top: 15px;
    font-size: 1.6rem;
    border-radius: 5px;
}

.Button {
    font-size: 15px ;
    margin-top: 20px;
    width: 350px;
    height: 40px;
    background-color: #00144a;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.4s;
}

.Button:hover {
    background-color: #53c0eb;
    color: white;
  }