.Logo {
    /* background-color: white; */
    padding: 3px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-flow: row;
    /* border: 2px solid #66BC45; */
    /* border-radius: 5px; */
}

.Logo img {
    height: 70%;
}

.Logo h6 {
    align-items: center;
    color: white; 
    line-height: 38px;
    font-size: 1rem;
    /* font-weight: bold; */
    letter-spacing: 0.5px;
    text-decoration: none;
}

.EnvWarning {
    color: white;
    background-color: darkred;
    margin-left: 25px;
    padding: 10px 100px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
    /* border: 1px solid white; */
}


