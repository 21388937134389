.SignUp {
    text-align: center;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
}

.Border {
    text-align: center;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    border: 2px solid #ccc;
    background-color: #f0f0f0; 
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    margin-top: 30px;
}

.InputClass {
    width: 350px;
    height: 40px;
    margin-top: 15px;
    font-size: 1.5rem;
}

.Button {
    margin-top: 20px;
    width: 110px;
    height: 40px;
    background-color: #00144a;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}